.footer-block {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  z-index: 5;
  -webkit-pointer-events: none;
  pointer-events: none;
  background-color: #020203;

  .footer {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: end;
    -ms-flex-align: end;
    -webkit-pointer-events: auto;
    pointer-events: auto;

    p {
      margin: 0;
      font: 300 18px/1.2 "Roboto", sans-serif;
      color: #8C9ABE;
    }

    .footer-item {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;

      &.footer-item-author {
        padding-bottom: 0;
      }

      @media (max-width: 500px) {
        width: 100% !important;
        text-align: left;
        padding: 0 !important;

        &:nth-child(n+2) {
          margin-top: 6px;
        }
      }
    }

    @media (max-width: 700px) {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }

    @media (max-width: 500px) {
      margin: 0 !important;
    }
  }

  @media (max-width: 740px) {
    padding: 0 0 35px;
  }
}